.content-block {
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: $box-shadow;

    &:last-child {
        margin-bottom: 0;
    }
}

.content-row {
    display: flex;

    &:last-child {
        .content-item {
            margin-bottom: 0;
        }
    }
}

.content-item {
    flex: 1;
    margin: 0 15px 20px;
    font-size: 14px;

    b {
        font-weight: 900;
    }

    img {
        width: 100%;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    label {
        @include mainFontSemiBold;
    }

    a:not(.button) {
        color: $link-blue;
        text-decoration: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.split-page {
    display: flex;
    gap: 16px;

    > * {
        &:first-child {
            flex-basis: 50%;
        }

        &:last-child {
            flex-basis: 50%;
        }
    }
}
