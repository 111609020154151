@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

// colours
$button-grey: #dddddd;
$button-blue: #007bff;
$button-green: #28a745;
$button-yellow: #ffc107;
$button-red: #dc3545;
$form-highlight: #222222;
$form-border: #cccccc;
$form-error: #ea7b73;
$form-warning: #f0ad4e;
$main-text: #222222;
$overlay-grey: rgba(0, 0, 0, 0.3);

$light-red: rgba(235, 204, 203);
$light-green: rgba(212, 241, 203, 1);

$dark-mode-main-background: #181818;
$dark-mode-header: #292828;
$dark-mode-primary-text: #ffffff;
$dark-mode-secondary-text: #c5c5c5;
$dark-mode-dark-background: #252020;
$dark-mode-nav-background: #212121;
$dark-mode-input-background: #292828;
$dark-mode-border: #424242;

$dark-mode-accent-colour: #1bb8b0;

$dark-mode-input-modal: #636363;
$dark-mode-option-background: #424242;

// fonts
@mixin mainFont() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin mainFontLight() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

@mixin mainFontSemiBold() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

// misc
$border-radius: 3px;

@mixin fullWidth() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// media queries
@mixin screenSize($size) {
    @if $size == lgDesktop {
        // large desktop
        @media only screen and (max-width: 1599px) {
            @content;
        }
    } @else if $size == mdDesktop {
        // medium desktop
        @media only screen and (max-width: 1279px) {
            @content;
        }
    } @else if $size == smDesktop {
        // small desktop
        @media only screen and (max-width: 1099px) {
            @content;
        }
    } @else if $size == lsTablet {
        // landscape tablet
        @media only screen and (max-width: 1023px) {
            @content;
        }
    } @else if $size == ptTablet {
        // portrait tablet
        @media only screen and (max-width: 800px) {
            @content;
        }
    } @else if $size == lgMobile {
        // large mobile
        @media only screen and (max-width: 767px) {
            @content;
        }
    } @else if $size == mdMobile {
        // medium mobile
        @media only screen and (max-width: 479px) {
            @content;
        }
    } @else if $size == smMobile {
        // small mobile
        @media only screen and (max-width: 413px) {
            @content;
        }
    } @else {
        // custom size
        @media only screen and (max-width: $size) {
            @content;
        }
    }
}
