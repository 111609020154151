.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $overlay-grey;
    opacity: 1;
    z-index: 10;
    overflow: hidden;
    transition: opacity 0.2s ease-in;

    &.hidden {
        width: 0;
        opacity: 0;
    }

    .modal-body {
        background-color: white;
        max-height: 90%;
        width: 90%;
        padding: 20px 20px;
        overflow-y: auto;
        border-radius: $border-radius;

        &.small {
            max-width: 600px;
        }

        &.medium {
            max-width: 900px;
        }

        &.large {
            max-width: 1200px;
        }
    }

    &.dark-mode {
        //start of dark mode
        .modal-body {
            background-color: $dark-mode-header;

            h1,
            p,
            b {
                color: $dark-mode-primary-text;
            }

            .form-sub-title {
                color: $dark-mode-primary-text;
            }

            .form-note {
                color: $dark-mode-secondary-text;
            }

            .form-row {
                .form-field {
                    color: $dark-mode-secondary-text;

                    .form-select,
                    .multi-select,
                    .simple-file-picker,
                    input {
                        background-color: $dark-mode-input-modal;
                        border-color: $dark-mode-nav-background;
                        color: $dark-mode-primary-text;

                        &.error {
                            border-color: $form-error;
                        }

                        .option {
                            background-color: $dark-mode-option-background;
                        }
                    }
                }

                i {
                    color: $dark-mode-secondary-text;
                }
            }

            .form-select-options {
                background-color: $dark-mode-input-modal;
                border-color: $dark-mode-border;

                .option {
                    background-color: $dark-mode-input-modal;

                    &:hover {
                        background-color: $dark-mode-input-background;
                    }
                }
            }

            .button {
                &.blue {
                    background-color: $dark-mode-accent-colour;
                }

                &.grey {
                    background-color: $dark-mode-secondary-text;
                }
            }

            .address-lookup {
                .options {
                    background-color: $dark-mode-input-modal;
                    border-color: $dark-mode-border;

                    .option {
                        background-color: $dark-mode-input-modal;
                        color: $dark-mode-primary-text;

                        &:hover {
                            background-color: $dark-mode-input-background;
                        }
                    }
                }
            }
        }

        .page-description {
            color: $dark-mode-secondary-text;
        }

        //end of dark mode
    }
}
