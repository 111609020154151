.page-title,
.page-description {
    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }
}

.page-title-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 15px;

    .back-button {
        padding: 4px 14px;
        height: 30px;
        margin: 0 !important;

        i {
            margin-right: 8px;
        }

        span {
            margin-top: -1px;
        }
    }

    .page-title {
        font-size: 22px;
        @include mainFontSemiBold;
    }
}

.page-description {
    margin-bottom: 20px;
    font-size: 14px;
}
