@import 'variables.scss';

html,
body,
#root,
#admin-body {
    height: 100%;
    overflow: hidden;
}

// wrapper
#admin-body {
    display: flex;
    flex-direction: column;
}

#admin-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}

#admin-content {
    flex: 1;
    padding: 20px;
    overflow: auto;
}

// Main body wrapper
.body {
    &.dark-mode {
        //Dark mode styles in here
        background-color: $dark-mode-main-background;

        .header {
            background-color: $dark-mode-header;
        }

        .form-sub-title {
            color: $dark-mode-primary-text;
        }

        .form-note {
            color: $dark-mode-secondary-text;
        }

        .navigation {
            background-color: $dark-mode-nav-background;

            .nav-item {
                p,
                .icon,
                a {
                    color: $dark-mode-primary-text;
                    opacity: 1;
                }
            }

            .nav-group {
                p {
                    color: $dark-mode-primary-text;
                }
            }
        }

        .page-title,
        .form-label {
            color: $dark-mode-primary-text;
        }

        .form-input,
        .form-select {
            background-color: $dark-mode-input-background;
            color: $dark-mode-secondary-text;
            border-color: $dark-mode-border;

            &.error {
                border-color: $form-error;
            }

            .remove {
                color: $dark-mode-secondary-text;
            }
        }

        .form-checkbox .content p,
        .form-radio .content p {
            color: $dark-mode-primary-text;
        }

        .form-checkbox .content {
            .outer-box {
                border-color: $dark-mode-border;
                background-color: $dark-mode-input-background;
            }

            .inner-box {
                &.active {
                    color: $dark-mode-accent-colour;
                }
            }
        }

        .form-radio .content {
            .outer-box {
                border-color: $dark-mode-border;
                background-color: $dark-mode-input-background;
            }

            .inner-box {
                &.active {
                    background-color: $dark-mode-accent-colour;
                }
            }
        }

        .form-datepicker {
            i {
                color: $dark-mode-secondary-text;
            }
        }

        .form-select-options {
            background-color: $dark-mode-input-background;
            color: $dark-mode-secondary-text;
            border-color: $dark-mode-border;

            .options-list {
                .option {
                    &:hover,
                    &.active {
                        background-color: $dark-mode-nav-background;
                    }
                }
            }
        }

        .checkbox {
            .text {
                color: $dark-mode-primary-text;
            }

            .outer {
                background-color: $dark-mode-secondary-text;
                color: $dark-mode-main-background;
            }

            input {
                &:checked {
                    + label,
                    + input + label {
                        .outer {
                            background-color: $dark-mode-accent-colour;
                        }
                    }
                }
            }
        }

        .table-search {
            input {
                background-color: $dark-mode-input-background;
                border: 1px solid $dark-mode-border;
                color: $dark-mode-primary-text;

                &::placeholder {
                    color: $dark-mode-secondary-text;
                }
            }
        }

        .table-pagination .page-selection {
            .title,
            .count {
                color: $dark-mode-secondary-text;
            }

            .form-select {
                border-color: $dark-mode-border;
            }
        }

        .page-links {
            i {
                color: $dark-mode-primary-text;
            }
        }

        table {
            th {
                color: $dark-mode-primary-text;
            }
            tr {
                color: $dark-mode-secondary-text;

                &.red {
                    background-color: transparent;
                    color: $red;
                }

                &.green {
                    background-color: transparent;
                    color: $green;
                }
            }
        }

        .button {
            &.blue {
                background-color: $dark-mode-accent-colour;
            }

            &.grey {
                background-color: $dark-mode-secondary-text;
            }
        }

        .content-block {
            background-color: $dark-mode-nav-background;
            box-shadow: 0px 1px 1px 1px $dark-mode-dark-background;

            label {
                color: $dark-mode-primary-text;
            }

            p {
                color: $dark-mode-secondary-text;
            }
        }

        // end of dark mode
    }
}
// login form
.login-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .login-form {
        width: 600px;
        padding: 20px;
        box-shadow: $box-shadow;
    }

    form {
        width: 100%;
    }
}

// create header
.create-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .page-title {
        margin-bottom: 0;
    }

    .button {
        margin-left: 15px;
    }
}

// loading
.loading {
    text-align: center;
    font-size: 22px;
}

.notification-status {
    .status-text {
        font-weight: 900;

        &.not-sent {
            color: $status-grey!important;
        }
    
        &.queued {
            color: $status-blue!important;
        }
    
        &.sending {
            color: $status-yellow!important;
        }
        
        &.sent {
            color: $status-green!important;
        }
    
        &.failed {
            color: $status-red!important;
        }
    }

    .info {
        margin-left: 5px;
    }
}

.error-text {
    color: $form-error;
    font-weight: 900;
    margin: 8px 0;
}

.empty-text {
    font-weight: 900;
    margin: 8px 0;
}

.import-customers-form {
    .example-table-wrapper {
        margin: 30px 0;

        .example-table {
            width: 100%;
            margin-top: 20px;

            .column {
                gap: 8px;
            }
        }
    }
}

// options dropdown
.options {
    .empty-option {
        padding: 1px 15px;
    }
}

.import-export-button {
    margin-top: 20px;
}

.fetch-spinner {
    color: $dark-mode-secondary-text;
    display: inline-block;
}

.custom-file-input {
    color: inherit;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 10px;
}
.custom-file-input::-webkit-file-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    background-color: #dddddd;
    border-radius: 3px;
    border-width: 0;
    outline: none;
    transition: opacity 0.2s ease-in-out;
    text-decoration: none;
    margin-bottom: 15px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

@import 'components/header.scss';
@import 'components/nav.scss';
@import 'components/contentBlock.scss';
@import 'components/tooltip.scss';
