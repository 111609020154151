// header
.header {
    display: flex;
    justify-content: space-between;
    flex: 0 0 75px;
    width: 100%;
    background-color: #353535;

    .logo {
        display: flex;
        width: 200px;
        align-items: center;
        justify-content: center;

        img {
            height: 35px;
        }
    }

    .content {
        flex: 1;
    }

    .dark-mode-switch {
        margin-right: 20px;
        display: flex;
        align-items: center;
    }
}
