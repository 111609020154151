@import 'reset.scss';
@import 'variables.scss';
@import 'structure.scss';

body {
    @include mainFont();
    color: $main-text;
    line-height: 1.5;
}

.custom-scroll {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin: 10px 0px 10px 0px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        opacity: 0.1;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: $button-grey;
    }
}

// loading
.loading {
    text-align: center;
    font-size: 22px;
}

// error
.error-text {
    width: 100%;
    color: $form-error;
    text-align: center;
    font-size: 18px;
}

// components
@import 'components/button.scss';
@import 'components/form.scss';
@import 'components/modal.scss';
@import 'components/table.scss';
@import 'components/typography.scss';
@import 'components/filepicker.scss';
