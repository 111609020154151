// colours
$link-blue: #007bff;
$main-text: #222222;
$form-error: #ea7b73;

$green: #28a745;
$red: #dc3545;

$dark-mode-main-background: #181818;
$dark-mode-header: #292828;
$dark-mode-primary-text: #ffffff;
$dark-mode-secondary-text: #b8b8b8;
$dark-mode-border: #424242;
$dark-mode-dark-background: #252020;
$dark-mode-nav-background: #212121;
$dark-mode-input-background: #292828;

$dark-mode-accent-colour: #1bb8b0;

$status-grey: #b8b8b8;
$status-blue: #0275d8;
$status-yellow: #f0ad4e;
$status-green: #5cb85c;
$status-red: #dc3545;

// fonts
@mixin mainFont() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

@mixin mainFontLight() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

@mixin mainFontSemiBold() {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

// misc
$box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
