// sub title
.form-sub-title {
    margin-bottom: 16px;
    font-size: 100%;
    font-weight: 900;
}

// note
.form-note {
    margin-bottom: 8px;
    font-size: 80%;
}

// row
.form-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .form-field {
        flex: 1;
        width: 100%;

        &:nth-child(2) {
            flex-grow: 1;
        }

        &.form-field-checkbox {
            flex-grow: 0;

            label {
                white-space: nowrap;
            }
        }

        > * {
            &:not(label) {
                width: 100%;
            }
        }
    }
}

// field
.form-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
    font-size: 14px;

    &.no-margin {
        margin-bottom: 0;
    }
}

// label
.form-label {
    margin-bottom: 5px;
}

// asterisk
.form-asterisk {
    margin-left: 2.5px;
    color: #aaa;
}

// error
.form-error {
    margin-top: 5px;
    color: $form-error;
}
// warning
.form-warning {
    margin-top: 5px;
    color: $form-warning;
}

.form-generic-error {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid $form-error;
    border-radius: $border-radius;
    background-color: rgba(234, 123, 115, 0.18);
    font-size: 14px;
    text-align: center;
}

// inputs, textarea and select
.form-input,
.form-select {
    padding: 12px 15px;
    @include mainFont();
    font-size: 13px;
    line-height: 24px;
    color: $main-text;
    background-color: white;
    border: 1px solid $form-border;
    border-radius: $border-radius;
    transition: border-color 0.2s ease-in-out;

    &:not(.multi-select) {
        height: 50px;
    }

    &:disabled,
    &.disabled {
        cursor: not-allowed;
        background-color: #f2f2f2;
    }

    &.warning {
        border-color: $form-warning;
    }

    &.error {
        border-color: $form-error;
    }

    // mini version
    &.mini {
        padding: {
            top: 7px;
            bottom: 7px;
        }
    }
}

textarea.form-input {
    min-height: 100px;
}

// input and textarea
.form-input {
    outline: none;

    &.text-area {
        resize: none;
    }

    &:focus {
        border-color: $form-highlight;
    }

    &::placeholder {
        color: #999;
    }
}

// select
.form-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;

    .arrow {
        padding-top: 4px;
        margin-left: 10px;
        padding-left: 10px;
        font-size: 22px;
        border-left: 1px solid #ccc;
    }

    p {
        flex: 1;

        &.placeholder {
            color: #999;
        }
    }

    .remove {
        margin-top: 2px;
        background-color: transparent;
        outline: none;

        i {
            font-size: 16px;
        }
    }

    // multi select
    &.multi-select {
        padding: 3px 15px 3px 5px;

        .selected {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
        }

        .placeholder {
            padding: 10px;
            padding-right: 0;
        }

        .option {
            display: flex;
            margin: 5px;
            padding: 5px 10px;
            background-color: #eee;
            border-radius: $border-radius;

            .remove {
                margin-left: 5px;

                i {
                    font-size: 14px;
                }
            }
        }

        &.disabled {
            .option {
                background-color: white;
            }
        }
    }

    // mini version
    &.mini {
        .arrow {
            margin-left: 30px;
        }
    }
}

// select options & search
.form-select-options {
    position: absolute;
    width: 100%;
    min-width: 400px;
    top: 100%;
    margin-top: 5px;
    background-color: white;
    border: 1px solid $form-border;
    border-radius: $border-radius;
    z-index: 9999;

    .search {
        padding: 15px;

        input {
            width: 100%;
            padding: 5px 15px;
        }
    }

    .options-list {
        max-height: 175px;
        overflow: auto;

        p {
            padding: 8px 15px;
        }

        .option {
            font-size: 13px;
            cursor: pointer;

            &:hover,
            &.active {
                background-color: #eee;
            }
        }
    }

    // mini version
    &.mini {
        min-width: 200px;
    }
}

// checkbox and radio buttons
.form-checkbox,
.form-radio {
    input {
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .content {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .outer-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border: 1px solid $form-border;
            background-color: white;
        }

        .inner-box {
            visibility: hidden;

            &.active {
                visibility: visible;
            }
        }

        p {
            flex: 1;
            margin-left: 10px;
        }

        &.disabled {
            cursor: not-allowed;

            .outer-box {
                background-color: #f2f2f2;
            }

            p {
                opacity: 0.5;
            }
        }
    }
}

// checkbox
.form-checkbox {
    .content {
        .outer-box {
            border-radius: $border-radius;
        }

        .inner-box {
            font-size: 18px;
        }
    }
}

// radio buttons
.form-radio-list {
    display: flex;
    flex-wrap: wrap;
}

.form-radio {
    margin-right: 20px;
    margin-bottom: 15px;

    .content {
        .outer-box {
            border-radius: 50%;
        }

        .inner-box {
            width: 13px;
            height: 13px;
            background-color: $main-text;
            border-radius: 50%;
        }
    }
}

// datepicker
.form-datepicker {
    display: flex;
    align-items: center;

    .form-input {
        width: 100%;
        padding-right: 40px;
    }

    .remove {
        position: absolute;
        right: 48px;
        margin-top: 4px;
        background-color: transparent;
        outline: none;

        i {
            font-size: 16px;
        }
    }

    .calendar {
        position: absolute;
        right: 15px;
        margin-top: 2px;
        padding: 5px 0 5px 10px;
        background-color: transparent;
        border-left: 1px solid #ccc;
        outline: none;
    }

    &.disabled {
        .calendar {
            cursor: not-allowed;
        }
    }
}

// datepicker widget
.react-datepicker__tab-loop {
    position: static;
}

// Toggle select
.checkbox {
    float: left;
    cursor: pointer;
    width: 100%;

    input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        margin: 0;
        z-index: 1;
        border: none;
        padding: 0;
        &:checked {
            + label,
            + input + label {
                .outer {
                    background-color: #007bff;
                }

                .inner {
                    left: calc(100% - 18px);
                }
            }
        }
    }
    label {
        float: left;
        width: 100%;
        font-size: 140%;
    }

    .outer {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 35px;
        height: 20px;
        border-radius: 20px;
        background-color: grey;
        transition: background-color 0.2s;
    }

    .text {
        width: 100%;
        padding-left: 45px;
        padding-right: 10px;
        box-decoration-break: clone;
        font-size: 16px;

        &.black {
            color: black;
        }
        &.white {
            color: white;
        }
    }

    .inner {
        position: absolute;
        left: 3px;
        top: 2.25px;
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 50%;
        transition: left 0.2s;
    }

    &.left {
        .outer {
            left: 0;
            right: auto;
        }
    }
    &.grey-out {
        opacity: 0.3;
    }

    &.hide {
        display: none;
    }

    &.small-text {
        width: 45px;

        label {
            float: left;
            width: 100%;
        }
        .text {
            float: left;
            width: 100%;
            font-size: 12px;
            line-height: 0.75;
            margin-bottom: 5px;
            padding: {
                left: 0;
                right: 0;
            }
        }
        .outer {
            float: left;
            margin-left: 3px;
            position: relative;
            top: 0;
            transform: none;
        }
    }
    .field-validation-error {
        border-top: 2px solid #9d9d9e;
    }
    &.with-subtext {
        width: 50px;
        padding-left: 10px;
    }
}

.simple-file-picker {
    display: inline-block;
    pointer-events: none;

    button {
        position: absolute;
        right: 0;
        margin-top: 5px;
        margin-right: 10px;

        background-color: transparent;

        pointer-events: all;

        .fal {
            font-size: 120%;
        }
    }

    .far {
        font-size: 120%;
        margin: 3px 10px 0 1px;
    }

    .wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        inset: 0;

        margin: 0 40px 0 15px;

        font-size: 100%;

        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
    }

    input {
        position: absolute;
        inset: 0;

        width: 100%;

        opacity: 0;

        pointer-events: all;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }
}

.address-lookup {
    flex-grow: 1;

    &:focus-within {
        .options {
            visibility: visible;
        }
    }

    .input {
        width: 100%;
    }

    .options {
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 300px;
        margin-top: 5px;

        background-color: white;
        border: solid 1px $form-border;
        border-radius: 3px;

        z-index: 100;
        visibility: hidden;
        overflow: auto;

        .option {
            padding: 10px 15px;
            width: 100%;

            background-color: white;

            color: $main-text;
            text-align: left;

            cursor: pointer;

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}
