.file-drop-container {
    border-radius: 2.5px;
    padding: 10px;
    border: 1px solid #cccccc;
    transition: 0.25s background-color ease-in-out, 0.25s box-shadow ease-in-out,
        0.25s border ease-in-out;

    &.can-drop {
        -webkit-box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        -moz-box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        box-shadow: inset 0px 0px 12px 0px #a5a5a5;
        &.file-over {
            background-color: #e6e6e6;
        }
    }
    p {
        text-align: center;
        .button {
            font-size: 12px;
            margin-left: 2.5px;
            margin-top: 10px;
            padding: 10px 35px;
        }
    }
    &.size-lg-12 {
        width: 100%;
    }
}

.file-picker-item {
    flex-grow: 0;
    flex-basis: 50%;
    min-width: 0;
}

.file-uploader {
    .button {
        font-size: 12px;
        margin-left: 2.5px;
        padding: 10px 35px;

        .icon {
            font-size: 14px;
        }

        &.delete {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }
    .file-item,
    .image-item {
        border-radius: 2.5px;
        margin: 2.5px 0;
        padding: 5px 7.5px;
        text-align: center;
        background-color: black;

        &:first-of-type {
            margin-top: 10px;
        }

        .top-row {
            margin: 8px;
            gap: 16px;

            .file-name {
                flex-grow: 1;

                text-align: left;
                color: white;
                margin-top: 2.5px;
                margin-bottom: 2px;

                min-width: 0;

                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        img {
            float: none;
            display: inline-block;
            margin-top: 10px;
            margin-bottom: 10px;
            max-height: 150px;
            max-width: 150px;
        }
    }

    .progress {
        text-align: center;
        padding: 5px;
        background-color: black;
        margin-top: 10px;
        border-radius: 2.5px;

        p {
            color: white;
            line-height: 1.6;
        }

        .button {
            margin-bottom: 5px;
        }
    }
}
