// table
table {
    width: 100%;
    font-size: 14px;

    thead {
        tr {
            border-top: none;
            border-bottom-width: 2px;
        }
    }

    tr {
        border: 1px solid $form-border;
        border: {
            left: none;
            right: none;
        }

        &.red {
            background-color: $light-red;
        }

        &.green {
            background-color: $light-green;
        }
    }

    th,
    td {
        padding: 15px;
        text-align: left;
    }

    td {
        .form-checkbox {
            align-self: center;
        }
    }

    th {
        cursor: default;
        user-select: none;
        @include mainFontSemiBold;

        i {
            margin-right: 10px;
        }
    }

    .button {
        padding: 6px 25px;
        font-size: 13px;
    }

    .loading-row {
        font-size: 22px;
        text-align: center;
    }

    .no-data-row {
        td {
            padding: 20px 15px;
            text-align: center;
        }
    }

    a {
        color: $dark-mode-accent-colour;
        text-decoration: none;
    }
}

// pagination
.table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;

    .page-links {
        display: flex;

        li {
            margin-right: 10px;
        }

        button {
            background-color: transparent;
            font-size: 16px;
            color: $main-text;
            outline: none;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.3;
            }
        }
    }

    .page-selection {
        display: flex;
        align-items: center;
        margin-right: 40px;

        label {
            font-size: 14px;
        }

        .title {
            margin-right: 15px;
        }

        .count {
            margin-left: 15px;
        }
    }
}

// header
.table-header {
    display: flex;
    align-items: flex-end;
    margin: 8px 0;
    gap: 32px;
    width: 100%;

    .form-field-checkbox {
        flex-direction: row-reverse;
        align-items: center;
        gap: 8px;

        label {
            margin: 0;
        }
    }

    .form-field {
        margin: 0;
    }

    .header-search {
        display: flex;
        align-items: flex-end;
        margin: 8px 0;
        gap: 32px;
        width: 100%;

        > * {
            flex-basis: 400px;
        }

        .form-field {
            flex-wrap: nowrap;
            margin: 0;
            min-width: 0;

            .form-label {
                min-width: 0;
            }

            .form-select,
            .form-input {
                padding: 4px 16px;
                height: 33px;
                min-width: 0;
            }

            .form-select {
                p {
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .button {
            padding: 4px 16px;
            height: 33px;
        }
    }

    // search
    .table-search {
        display: flex;
        align-items: center;
        padding-left: 10px;

        .icon {
            font-size: 18px;
            color: #aaa;
            margin-right: 5px;
        }

        input {
            width: 100%;
            padding: 5px 10px;
            border: none;
            outline: none;
            @include mainFont();
            font-size: 14px;
            line-height: 1.5;
            color: $main-text;
            border-radius: 3px;
            &::placeholder {
                color: #aaa;
            }
        }
    }
}
